.footer-bottom-section {
    background-color: #fafafa;
    box-shadow: 1px 0 8px 3px #eee;
    padding: 15px 0;
    /* background-image: url("../img/footer.jpg");
    background-repeat: no-repeat;
    background-position: center bottom; */
}

.footer-center {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.footer-bottom {
    font-size: 14px;
    padding: 5px 0;
    color: #222 !important;
}

.footer-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-list li {
    padding: 0 5px;
}

.social-link {
    list-style: none;
    margin: 0;
    padding: 0;
}

.social-link li {
    display: inline-block;
    padding: 5px;
    font-size: 22px;
}

.footer-list li a {
    color: #666 !important;
    font-size: 12px;
}

.footer-list li a:hover {
    color: #D10A10 !important;
}

.footer-help {
    text-align: center;
    color: #888;
    font-size: 12px;
}