.react-datepicker-wrapper{
    display: block !important;
}

.react-datepicker__input-container{
    display: block !important;
}

.react-datepicker-popper{
    z-index: 10;
}

.react-datepicker__month-year-dropdown-container select{
    font-family: Avenir;
}

.react-datepicker__header {
    background-color: #f0146b;
}

.react-datepicker__day-name{
    color: #fff;
    font-weight: 700;
}

.react-datepicker__navigation--next {
    border-left-color: #fff !important;
}

.react-datepicker__navigation--previous{
    border-right-color: #fff !important
}

.react-datepicker__current-month, .react-datepicker-time__header{
    color: #fff !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: #f0146b !important;
}

.react-datepicker__month-select, .react-datepicker__year-select{
    padding: 0px 12px;
    border: none !important;
    box-shadow: none !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    background-color: #f0146b;
}