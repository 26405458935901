/* common css */

:root {
    --theme-primary: #135fd9;
    --theme-secondary: #52d5ae;
    --theme-support: #D10A10;
}

@font-face {
    font-family: Avenir;
    src: url("../fonts/Avenir.otf");
    font-display: swap;
}

body {
    color: #212529;
    font-family: Avenir;
    line-height: 1.8;
}

/*modal css*/

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.8);
}

.modal-container {
    max-width: 550px;
    width: 100%;
    height: auto;
    background: #fff;
    margin: auto;
    overflow: auto;
    pointer-events: all;
    padding: 10px;
    border-radius: 25px;
    position: relative;
    max-height: 100%;
}

.modal-heading {
    padding: 10px 15px;
}

.btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
}

.btn-matches-normal {
    border: 1px solid #777;
    background-color: #fff;
    color: #000;
    padding: 7px 21px;
    border-radius: 20px;
    font-size: 15px;
}

.btn-matches-normal:hover {
    color: #000
}

.btn-matches-active {
    border: 1px solid var(--theme-primary);
    background-color: var(--theme-primary);
    color: #fff;
    padding: 8px 21px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 700;
}

.btn-matches-active:hover {
    color: #fff;
}

.modal-close {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
}

.page-loader-container {
    background: transparent;
}

.bannerImage {
    background-size: cover;
    height: 350px;
    background-position: center;
}

.bannerImage .row {
    height: 100%;
}

.landing-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.custom-container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0;
    float: none;
}

.lft-panelbg-home {
    float: left;
    box-shadow: 1px 0 8px 3px #eee;
    border-radius: 12px;
    z-index: 9;
    padding: 10px;
}

.lft-profile {
    border-bottom: 2px solid #52d5ae;
}

.lft-profile-img {
    display: flex;
    justify-content: center;
    width: 100%;
}

.lft-profile-content {
    padding: 10px 0;
    text-align: center;
}

.lft-profile-name {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: .8px;
    color: #000;
}

.lft-profile-img img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 16px;
}

.scrollable-myhome {
    width: 100%;
    position: sticky;
    z-index: 2;
    float: left;
}

.left-scroll-inside {
    float: left;
    width: 100%;
}

.rt-my-home {
    float: right;
    width: calc(100% - 225px);
}

.border-radius-7 {
    border-radius: 7px;
}

/* Card */

.card {
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
}

.card-header {
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

/* end card */

.bg-heading {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    height: 520px;
}

.divider {
    position: relative;
    margin-top: 25px;
    height: 1px;
}

.div-transparent:before {
    content: "";
    position: absolute;
    top: 0;
    right: 35%;
    width: 30%;
    height: 1px;
    background-image: linear-gradient( to right, transparent, rgb(48, 49, 51), transparent);
}

.custom-btn {
    min-width: 100px;
    border-radius: 3px;
    color: white !important;
    font-size: 12px;
    border: 1px solid var(--theme-primary);
    padding: 6px 12px;
    font-weight: 500;
    background-color: var(--theme-primary);
    min-height: 30px;
}

.icon-round {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 2px solid #000;
}

.icon-round img {
    width: 80px;
    height: 80px;
    transition: all 0.2s ease 0s;
}

.icon-round img:hover {
    transform: rotate(360deg);
}

.color-white {
    color: #fff;
}

.color-black {
    color: #000;
}

.custom-btn:hover {
    color: white;
}

.btn-rounded {
    border-radius: 20px;
}

.round-btn {
    border: 1px solid transparent;
    border-radius: 50%;
    padding: 0;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.round-btn:focus {
    outline: none;
}

.logout-btn {
    background: transparent;
    padding: 2px 10px;
    display: block;
    color: #515151;
    font-size: 16px;
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.file-upload-btn {
    position: relative;
    left: -40%;
    height: 38px;
    margin: 0;
    min-width: 120%;
    color: #fff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    background-color: #515151;
}

.file-upload-btn:hover {
    background-position: right center;
}

.file-sqaure {
    height: 150px;
    width: 150px;
    border: 5px dashed rgba(112, 112, 112, 0.5);
    color: rgba(112, 112, 112, 0.5);
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    cursor: pointer;
}

.btn:focus {
    box-shadow: none;
}

a h1, a h2, a h3, a h4, a h5, a h6 {
    color: #515151;
}

.btn-link {
    padding: 0;
    margin: 0;
    color: #333;
}

a:hover, .btn-link:hover {
    color: var(--theme-support);
    text-decoration: none;
}

.text-underline {
    text-decoration: underline;
}

.text-striked {
    text-decoration: line-through;
}

.text-cap {
    text-transform: capitalize;
}

.gradientbg {
    background-color: #D10A10;
    color: #fff;
}

/* header csss starts here */

.header {
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 10;
}

.header-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.header-height {
    height: 60px;
}

.header-land-height {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0px;
}

.header-border {
    border-bottom: 2px solid #52d5ae;
}

.headericon {
    font-weight: 600;
    margin-right: 5px;
}

.logo {
    height: 80px;
    padding: 5px 0;
}

.header-signIn {
    display: block;
}

.signInMbtn {
    display: none;
}

.header-links .material-icons {
    padding-right: 2px;
    font-size: 15px;
}

.header-links .header-span {
    position: relative;
    color: #424242;
    letter-spacing: .7px;
    font-size: 14px;
    text-transform: uppercase;
}

.header-links .active .header-span {
    position: relative;
    font-weight: 700;
    font-size: 14px;
    color: #4253af;
    text-transform: uppercase;
}

@keyframes changeWidth {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.ham-btn {
    padding: 0;
    margin: 0;
    background: transparent;
    display: none;
}

.herderip-height {
    height: 35px;
}

/* header css ends here */

.home-section {
    height: calc(100vh - 60px);
}

/* 
.border,
.border-left,
.border-right,
.border-top {
    border-color: rgba(112, 112, 112, 0.5) !important;
} */

.border-box-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: #333333;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 500;
}

.form-control {
    border-radius: 3px;
    font-size: 12px;
    min-height: 32px !important;
    border: 1px solid rgba(112, 112, 112, 0.5);
}

.form-title {
    color: #222222;
    font-size: 20px;
}

p.ml-1.f-500.f-16.mb-0 {
    align-items: center;
    height: 100%;
    display: flex;
    color: #666666;
}

.form-control:focus {
    box-shadow: none;
    border: 1px solid #2684ff;
}

.react-select__control {
    border-radius: 3px !important;
    border: 1px solid rgba(112, 112, 112, 0.5) !important;
    font-size: 12px !important;
    padding-left: 5px;
    min-height: 32px !important;
}

.react-select__option {
    padding: 6px 12px !important;
    font-size: 12px !important;
}

.react-select__indicator {
    padding: 5px 12px !important;
    color: #c8c8c8 !important;
}

.react-select__option--is-selected {
    background-color: #D10A10 !important;
}

.react-select__option--is-focused {
    color: #fff !important;
    background-color: #757575 !important;
}

.react-select__indicator-separator {
    display: none;
}

.react-select__group-heading {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #222 !important;
}

.nav-link {
    font-weight: 500;
    color: #515151 !important;
}

.theme-color {
    color: var(--theme-primary) !important;
}

.theme-secondary-color {
    color: var(--theme-secondary) !important;
}

.theme-support-color {
    color: var(--theme-support) !important;
}

.nopadding {
    padding: 0;
}

hr[data-title] {
    margin: 2.78571429em 0;
    text-align: center;
}

hr[data-title]:before {
    content: attr(data-title);
    background: #fff;
    position: relative;
    bottom: 14px;
    padding-left: 0.92857143em;
    padding-right: 0.92857143em;
    font-style: italic;
    font-size: 12px;
}

.relative {
    position: relative;
}

.signup-submit-btn {
    width: 100%;
}

.signup-form-container {
    background: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.27);
}

.pt-100 {
    padding-top: 100px;
}

.f-800 {
    font-weight: 800;
}

.f-600 {
    font-weight: 600;
}

.f-500 {
    font-weight: 500;
}

.f-12 {
    font-size: 12px;
}

.f-14 {
    font-size: 14px;
}

.f-16 {
    font-size: 14px;
}

.f-18 {
    font-size: 18px;
}

.f-20 {
    font-size: 20px;
}

.f-22 {
    font-size: 22px;
}

.f-24 {
    font-size: 24px;
}

.f-26 {
    font-size: 26px !important;
}

.f-30 {
    font-size: 30px;
}

.f-60 {
    font-size: 60px;
}

.greybg {
    background: #fbfbfb;
}

.clr-black1 {
    color: #000!important;
}

.clr-blue3 {
    color: #4a546e;
}

.clr-orange1 {
    color: #ed6402!important;
}

.small-txt1 {
    font-size: 12px;
    letter-spacing: .7px;
}

.small-txt2 {
    font-size: 14px;
    letter-spacing: .7px;
}

.cardio-img {
    width: 250px;
}

.top-loader {
    background: #ebedf0;
    margin-bottom: 25px;
}

.top-loaded {
    height: 100%;
    border-radius: 0px 11px 11px 0px;
}

.top-profilepic {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    float: right;
}

/* range slider */

.input-range__label {
    color: #515151 !important;
}

.input-range__label--min, .input-range__label--max {
    display: none !important;
}

.input-range__slider {
    border: 1px solid transparent !important;
}

/* 
.input-range__track--active{
    background: #ee0979 !important;
} */

.radio-ip input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.radio-ip input[type="checkbox"]+.radio-label:before {
    content: "";
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    margin-right: 10px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
}

.radio-ip input[type="checkbox"]:checked+.radio-label:before {
    background-color: #f71455;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}

.radio-ip input[type="checkbox"]:focus+.radio-label:before {
    outline: none;
    border-color: #3197ee;
}

.radio-ip input[type="checkbox"]:disabled+.radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
}

.radio-ip input[type="checkbox"]+.radio-label:empty:before {
    margin-right: 0;
}

.radio-ip label {
    margin: 0;
    color: #515151;
    font-size: 14px;
}

/* register page */

.form-box {
    position: relative;
}

.form-box-number {
    position: absolute;
    top: -23px;
    right: 25px;
    height: 46px;
    width: 46px;
    background: linear-gradient(#ff6a00 0%, #D10A10 100%);
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-clip: padding-box;
}

.form-box-underline {
    height: 8px;
    width: 60%;
    border-radius: 12px;
    margin-top: 15px;
    background: linear-gradient(#ee0979 0%, #ff6a00 100%);
}

.registerbtn {
    width: 200px;
}

.border {
    border: 1px solid #dee2e6 !important;
}

/* dashboard page */

.dash-filterbtn {
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 5px 20px;
}

.dash-filterbtn-active {
    background: var(--theme-primary);
    font-weight: 600;
    color: #fff !important;
}

.dash-bg {
    color: white;
    background-image: linear-gradient(to right, #D10A10, #48d9dd);
}

.sticky-dash-nav {
    position: sticky;
    top: 75px;
}

.card-profile {
    margin-bottom: 25px;
    border-radius: .25rem;
    transition: all .6s ease;
    border: 1px solid #ececec;
    background: #fff!important;
}

.card-profile:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
}

.card-profile-img {
    width: 100%;
    object-fit: cover;
    height: 235px;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
}

.card-profile-img-con {
    position: relative;
}

.f-13 {
    font-size: 13px;
}

.logo-red-color {
    color: #4253af !important;
}

.logo-blue-color {
    color: #1cd0d4;
}

.match-func {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.match-func-item {
    width: 100%;
}

.match-func-btn {
    background: transparent;
    padding: 0;
    line-height: 0;
    height: 45px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    transition: 0.3s;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.match-func-btn:disabled {
    opacity: 1 !important;
}

.match-func-btn img {
    height: 16px;
    width: 16px;
    margin-bottom: 8px;
}

.match-func-btn p {
    font-size: 10px;
    color: #515151;
    font-weight: 600;
    margin: 0;
}

.border-right {
    border-right: 1px solid #dee2e6;
}

.is-online-dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: relative;
}

.online {
    background: green;
}

.offline {
    background: orange;
}

.online::before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: rgba(0, 255, 0, 0.2);
    animation: ripple 1s infinite;
}

@keyframes ripple {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(4);
    }
}

/* profile page */

.profile-icon {
    height: 26px;
    margin-right: 10px;
}

.profile-details {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
}

.profile-details li {
    padding: 5px;
}

.profile-list-main ul {
    list-style: none;
    padding-left: 0;
    columns: 2;
    width: 100%;
}

.profile-list-main ul li {
    margin-bottom: 15px;
    display: inline-flex !important;
    width: 100%;
    vertical-align: top;
    font-size: 16px;
    color: #000 !important;
}

.profile-box-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 900;
    padding: 5px 15px;
    margin-bottom: 5px;
}

.profile-box-head, .profile-box-head a {
    color: #000000 !important;
}

.profile-box-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-top: 2px;
}

.profile-content h6, .profile-details li h6 {
    margin: 0;
    word-wrap: break-word;
}

.profile-content p, .profile-details li p {
    margin: 0;
    font-size: 14px;
}

.partner-preferences p {
    margin-bottom: 5px;
    font-size: 14px;
}

.partner-match {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.color-green {
    color: green;
}

.color-red {
    color: red;
}

.sticky {
    position: sticky;
    top: 5px;
}

.tag {
    margin-right: 2px;
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 12px;
    padding: 0px 10px;
    border: 1px solid #dee2e6;
}

/* search page */

.search-by-id-btn {
    margin-left: 5px !important;
    height: 34px;
    margin: 0;
    width: 100%;
    color: #fff !important;
    border-radius: 5px;
    font-weight: 600;
    background: var(--theme-primary);
}

.round-profile-pic {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.manage-heading {
    padding: 8px 10px;
    background: #eee;
    font-weight: 600;
}

.manage-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.manage-list li a {
    padding: 2px 10px;
    display: block;
    color: #515151;
    font-size: 16px;
}

.manage-border {
    border: 1px solid #cecece;
}

.manage-link-active {
    color: #ee0979 !important;
}

.border-bottom-last:last-child {
    border-bottom: none !important;
}

.page-heading {
    min-height: 150px;
    background-image: url("../img/recbg.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.page-heading h1 {
    font-size: 2.8rem;
    font-weight: 800;
    color: #fff;
    margin: 0;
}

.content-box {
    line-height: 1.8;
}

.form-padding-left {
    padding-left: 0.75rem;
}

.form-control-err {
    position: relative;
}

.form-control-err .form-control, .form-control-err .react-select__control {
    border: 2px solid #D10A10 !important;
}

.form-group {
    position: relative;
    min-height: 45px;
    margin-bottom: 0px;
}

.form-group p {
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.errtxt {
    color: #f71455;
}

.image-crop-container {
    border: 1px solid #cecece;
    width: 250px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 8px;
}

/* .upload-photo {
    min-height: 300px;
    flex: 0 0 250px;
    margin-bottom: 25px;
    margin-right: 25px;
} */

.ribbon {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--theme-support);
    font-size: 12px;
    color: #fff;
    padding: 0px 5px;
    z-index: 1;
}

.upload-photo-top img {
    height: 100%;
    object-fit: contain;
}

.upload-photo-top {
    height: 230px;
    border: 2px dotted #dcdcdc;
}

.upload-photo-bottom {
    height: 50px;
}

.page-loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.thumbnail {
    height: 75px;
    flex: 0 0 75px;
}

.profile-content h6 {
    word-wrap: break-word;
    font-size: 14px;
}

.profile-content p {
    word-wrap: break-word;
    margin: 0px;
}

.opcity-anim {
    animation: opcit 700ms infinite;
}

@keyframes opcit {
    0% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

.h100p {
    height: 100%;
}

.noti-image {
    height: 60px;
    border-radius: 50px;
    box-shadow: 1px 0 8px 3px #eee;
}

.chat-noti {
    background: #fff;
}

.noti {
    display: block;
    cursor: pointer;
    box-shadow: 1px 0 8px 3px #eee;
    padding: 10px;
    border-radius: 5px;
}

.new-noti {
    font-size: 10px;
    padding: 2px 10px;
    border-radius: 5px;
    box-shadow: 1px 0 8px 3px #eee;
    color: var(--theme-support);
    font-weight: 600;
    position: absolute;
    right: 20px;
    top: 5px;
}

.purchasedpackage {
    border-top: 3px solid #1bd81b;
}

.paper {
    background: #f71e5c;
    color: #fff;
    padding: 3px 10px;
    box-shadow: /* The top layer shadow */
    0 1px 1px rgba(0, 0, 0, 0.15), /* The second layer */
    0 10px 0 -5px #f71e5c, /* The second layer shadow */
    0 10px 1px -4px rgba(0, 0, 0, 0.15), /* The third layer */
    0 20px 0 -10px #f71e5c, /* The third layer shadow */
    0 20px 1px -9px rgba(0, 0, 0, 0.15);
    /* Padding for demo purposes */
}

/* checkbox toggle css */

.custom-tickbox {
    display: none;
}

.custom-tickbox+label {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 0px;
    margin-top: 5px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.custom-tickbox+label:before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    border: 1.5px solid #ef1071;
    position: absolute;
    left: 0;
    top: 4px;
    /* opacity: .6; */
    -webkit-transition: all 0.12s, border-color 0.08s;
    transition: all 0.12s, border-color 0.08s;
}

.custom-tickbox:checked+label:before {
    width: 10px;
    top: 0px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* ////////////////////////   */

.react-datepicker {
    font-family: Avenir !important;
}

/* ///////////// */

.phone-modal-container {
    max-width: 600px;
    width: 100%;
    height: auto;
    border-radius: 25px;
    margin: auto;
    overflow: auto;
    pointer-events: all;
}

.phonemodal-pic {
    border-radius: 50%;
    width: 130px;
    height: 130px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.phonemodal-pic img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.phonemodal-details {
    background: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.pt-75 {
    padding-top: 75px;
}

@keyframes slideOutDown {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
    to {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: hidden;
    }
}

.slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown;
}

/* side modal */

.side-modal-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.7);
}

.side-modal-container {
    top: 0;
    left: 0;
    height: 100%;
    -webkit-animation: slideInLeft 0.3s;
    animation: slideInLeft 0.3s;
    background: #fff;
    overflow: auto;
    pointer-events: all;
}

.side-modal-container .noti-badge {
    position: relative;
    top: 0px;
    right: -5px;
}

@keyframes slideInLeft {
    from {
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

.slideInLeft {
    animation-name: slideInLeft;
}

.side-account {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    padding: 10px;
}

/* payment details */

.payment-detail {
    padding: 5px 15px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.coupon-ip {
    width: 100%;
    padding: 5px 15px;
    border: 1px solid #eaeaea;
}

.pay-btn {
    border: none;
    font-size: 15px;
    border-radius: 0 0 10px 10px;
    padding: 10px;
}

.package {
    box-shadow: 1px 0 8px 3px #eee;
    border-radius: 12px;
    background-color: #fff;
}

.package .discout-percentage {
    top: 1px!important;
    right: 15px;
    z-index: 0;
    display: inline;
    padding: .6em 1em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: initial;
    border-radius: .25em;
    background: var(--theme-support);
    position: absolute;
}

.coupon-err {
    position: absolute;
    bottom: -20px;
    left: 3px;
}

.headerprofile:hover .logout-dropdown {
    display: block;
}

.logout-dropdown {
    display: none;
    position: absolute;
    background: #fff;
    min-width: 125px;
    top: 54px;
    right: 20px;
    z-index: 1;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.logout-dropdown-content {
    position: relative;
    padding: 5px 15px;
    list-style: none;
    margin-bottom: 10px;
}

.logout-dropdown-content li {
    background: #ffffff;
    color: #666;
}

.logout-dropdown-content li.active {
    color: #5380f7;
}

.logout-dropdown-content li:first-child {
    border-radius: 4px 4px 0 0;
}

.logout-dropdown-content li:last-child {
    border-radius: 0 0 4px 4px;
}

.logout-dropdown-content li:last-child a {
    border-bottom: 0;
}

.logout-dropdown-content a, .logout-dropdown-content .logout-btn {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 5px 0;
    color: inherit;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
}

.logout-dropdown-content::before {
    content: "";
    position: absolute;
    top: -6px;
    left: 20px;
    width: 0;
    height: 0;
    box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.05);
    border-top: 6px solid #ffffff;
    border-right: 6px solid #ffffff;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    mix-blend-mode: multiple;
}

.upgradelink {
    color: #ffffff;
    background-color: #4253af;
    border: 1px solid #4253af;
    font-weight: 700;
    font-size: 14px;
    margin-right: 10px;
    padding: 5px 10px;
}

.notification-count {
    top: 10px !important;
    position: absolute!important;
    background: var(--theme-support);
    color: #fff;
    font-size: 10px;
    font-weight: 600;
}

/* responsive css */

@media only screen and (max-width: 997px) {
    .header-links, .completed-txt, .upgradelink, .logout-dropdown {
        display: none !important;
    }
    .ham-btn {
        display: block;
    }
    .headerprofile:hover .logout-dropdown {
        display: none !important;
    }
}

.show-md-flex {
    display: none;
}

/* mutiple checkbox ip */

.multiple-checkbox input[type="checkbox"]+label {
    display: block;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 0;
}

.multiple-checkbox input[type="checkbox"] {
    display: none;
}

.multiple-checkbox input[type="checkbox"]+label:before {
    content: "\2714";
    border: 1px solid #b4b4b4;
    border-radius: 0.2em;
    display: inline-flex;
    width: 1.2em;
    height: 1.2em;
    margin-right: 10px;
    vertical-align: bottom;
    color: transparent;
    transition: 0.2s;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.multiple-checkbox input[type="checkbox"]+label:active:before {
    transform: scale(0);
}

.multiple-checkbox input[type="checkbox"]:checked+label:before {
    background-color: var(--theme-primary);
    border-color: var(--theme-primary);
    color: #fff;
}

.multiple-checkbox input[type="checkbox"]:disabled+label:before {
    transform: scale(1);
    border-color: #aaa;
}

.multiple-checkbox input[type="checkbox"]:checked:disabled+label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
}

.required {
    color: #D10A10;
    font-size: 18px;
    line-height: 13px;
}

.noti-badge {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-flex;
    font-size: 8px;
    background: #4253af;
    color: #fff;
    min-width: 16px;
    line-height: 0;
    min-height: 16px;
    border-radius: 8px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.about-profile img {
    /* border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; */
    border-radius: 25px;
    max-width: 100%;
}

.profile-pic img {
    border-radius: 25px;
    width: 282px;
    height: 282px;
    object-fit: cover;
}

.gallery-img {
    width: 75px;
    height: 75px;
    border-radius: 5px;
    background: #cecece;
    margin: 0 8px 8px 0;
}

.gallery-img img {
    width: 75px;
    height: 75px;
    border-radius: 5px;
    object-fit: cover;
}

.btn-outline-req {
    background-color: transparent;
    background-image: none;
    border-color: #dee2e6;
    color: rgb(81, 81, 81);
}

.choosedesc-list {
    list-style: none;
    padding: 0 !important;
}

.choosedesc-list li {
    display: inline-block;
    padding: 2px 10px;
    font-size: 14px;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}

.choosedesc-active {
    background: #eee;
}

.br-5 {
    border-radius: 5px;
}

.br-15 {
    border-radius: 15px;
}

.br-0 {
    border-radius: 0px;
}

.list-inline li {
    display: inline-block;
}

.social-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.social-list li {
    display: inline-block;
    margin-right: 15px;
}

.about-us-section a {
    color: rgb(81, 81, 81) !important;
}

.social-list li a {
    height: 40px;
    width: 40px;
    border: 1px solid rgb(81, 81, 81);
    border-radius: 50%;
    text-align: center;
    background: #fff;
    /* color: #f71455 !important; */
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us-section h4 {
    display: block;
    position: relative;
}

/* 
.about-us-section h4::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background: #F71456;
    position: absolute;
    bottom: 0;
    left: 0;
} */

/* //acordion */

.accordion {
    background-color: #eee;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 16px;
    transition: 0.4s;
}

/* .acc-active,
.accordion:hover {
    background-color: #ccc;
} */

.accordion:after {
    content: "\002B";
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.acc-active:after {
    content: "\2212";
}

.acc-panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.accordion:focus {
    outline: none;
}

/* new landing page */

.landing-section-pad {
    padding: 70px 0;
}

.download-bg {
    position: relative;
    background-image: url("../img/downloadbanner.jpg");
    background-size: cover;
    background-position: center center;
    color: #fff;
}

.download-bg div {
    z-index: 1;
}

.download-bg::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.15);
}

.mb-50 {
    margin-bottom: 50px;
}

.premium-label {
    position: absolute;
    top: 0px;
    right: 0;
    box-shadow: 1px 1px 9px 0px rgba(247, 20, 85, 0.48);
    font-size: 10px;
    padding: 0px 5px;
    background: rgba(247, 20, 85, 1);
    color: #fff;
    line-height: 15px;
    display: flex;
    align-items: center;
}

.premium-label i {
    font-size: 7px;
    margin-right: 2px;
}

.profile-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #222;
    font-size: 14px!important;
}

.profile-details .name {
    color: #333;
    font-weight: 900;
    font-size: 24px;
    letter-spacing: 1.5px;
}

.profile-details .small-desc {
    color: #666;
    font-size: 14px;
    letter-spacing: .7px;
}

.personal-infos ul {
    margin-top: 30px;
    columns: 2;
    list-style: none;
    margin-bottom: 10px;
}

.personal-infos ul li {
    color: #000;
    font-size: 14px;
    letter-spacing: .7px;
    margin-bottom: 10px;
    width: calc(100% - 20px);
}

.personal-infos ul li::before {
    content: "\2022";
    color: #d8d8d8;
    font-weight: 700;
    font-size: 18px;
    padding-right: 10px;
    line-height: 20px;
}

.text-break-word {
    word-wrap: break-word;
}

.info-banner {
    height: 300px;
    background-image: url("../img/infobanner.jpg");
    background-position: center center;
    background-size: cover;
}

.abs-tint {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.38);
}

/* horizontal Line */

h2.horizontalline {
    width: 30%;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    line-height: 0.1;
    margin: 0px auto;
    margin-top: 7px;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;
}

h2.horizontalline span.horizontaltext {
    background: #fff;
    padding: 0 10px;
}

/* Testmonial */

.wedding-block {
    box-shadow: 0px 0px 2px 0px rgba(159, 170, 188, 0.2);
    transition: all 0.2s ease-in-out 0s;
}

.wedding-block:hover {
    box-shadow: 0px 3px 15px 2px rgba(150, 147, 136, 0.5);
}

.wedding-img img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 0.25rem !important;
}

.wedding-info {
    background: #fff;
}

/* Endtestmonial */

.regfreebtn {
    font-size: 18px;
    border-radius: 10px;
    padding: 12px 30px 14px 30px;
    border: none !important;
}

.intro-details {
    padding: 50px 30px 41px 120px;
    background-color: #f2f2f2;
}

.subtitle {
    color: #b9b9b9;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 20px;
    margin-bottom: 25px;
    margin-top: 0;
}

.h1-title {
    font-size: 26px;
    font-weight: 400;
    margin: 0 0 20px;
    position: relative;
}

.text {
    font-size: 13.5px;
    line-height: 24px;
}

.browse-category-box {
    margin-top: 50px;
    padding: 20px;
}

.browse-category-box.outline {
    border: 1px solid #e5e5e5;
}

.a-1 {
    font-weight: 700;
    color: var(--theme-secondary)!important;
    font-size: 12px;
    letter-spacing: .5px;
    text-decoration: none!important;
}

.bx-shdw {
    box-shadow: 1px 0 8px 3px #eee;
    border-radius: 12px;
    padding: 20px;
    margin-top: 1.5rem!important;
}

.bx-shdw-title {
    font-weight: 700;
    color: #424242;
    font-size: 18px;
    letter-spacing: .8px;
}

.bx-shdw-sub-title {
    font-weight: 700;
    color: var(--theme-support)!important;
    font-size: 12px;
    letter-spacing: .5px;
    text-decoration: none!important;
    text-transform: uppercase;
}

.bx-shdw-content {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background: #fff;
    padding: 1rem!important;
}

.bx-shdw-img img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    min-height: 190px;
    max-height: 190px;
    object-fit: cover;
}

.bx-shdw-name {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    color: #333;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: .8px;
    line-height: 1.5;
}

.bx-shdw-id {
    padding-top: 2px;
    color: #5c6888;
    font-size: 12px;
    letter-spacing: .5px;
}

.bx-shdw-desc {
    line-height: 20px;
    color: #333;
    font-size: 14px;
    letter-spacing: .7px;
}

.bx-shdw-caste {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #000000;
}

.medium-txt1 {
    font-size: 16px;
    letter-spacing: .8px;
    color: #000!important;
}

.content-title {
    font-size: 14px;
    letter-spacing: .7px;
    color: #000!important;
}

/* Paginatiions */

.page-link, .page-link:hover {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    background: #ffffff;
    color: #222;
    border: 1px solid #dee2e6;
    margin-right: 5px;
    border-radius: 3px;
}

.page-item.active .page-link {
    background: var(--theme-primary);
    border: 1px solid var(--theme-primary);
}

/* End Paginations */

/* Terms Page */

.terms-list ul {
    margin-top: 30px;
    margin-bottom: 10px;
    list-style: none;
}

.terms-list ul li {
    color: #000;
    font-size: 13px;
    letter-spacing: .7px;
    margin-bottom: 10px;
}

.terms-list ul li:before {
    content: "\2022";
    color: #d8d8d8;
    font-weight: 700;
    font-size: 18px;
    padding-right: 10px;
    line-height: 20px;
}

/* End Page */

.browse-category-box {
    background: #fff;
    box-shadow: 0px 0px 10px 5px #eee;
    float: left;
    width: 100%;
    min-height: 310px;
    border-radius: 10px;
}

/* Upload Photos */

.photo-holder {
    background: #f8f8f8;
    position: relative;
    margin: 5px !important;
}

.image-uploader-wrapper {
    position: relative;
    cursor: pointer;
    opacity: 0;
    height: 16vh;
    width: 100%;
    min-height: 200px;
    z-index: 1;
}

.album-img-preview {
    border-radius: 5px;
    border: 2px dotted #cecece;
    width: 100%;
    height: 100% !important;
    position: absolute;
    left: 0;
    object-fit: cover;
}

.upload-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -32px;
    width: 63px;
    height: 59px;
}

/* End Upload Photos */

/*  */

@media only screen and (max-width: 576px) {
    .header-land-height {
        padding: 0;
        justify-content: space-between;
    }
    .bannerImage {
        height: 150px;
    }
    .landing-section-pad {
        padding: 25px 0;
    }
    .my-card-profile{
        padding: 0 10px;
    }
    .dash-profile{
        padding: 0 5px;
    }
}

@media only screen and (max-width: 997px) {
    .landing-img {
        display: block !important;
    }
    .bannerImage {
        background: none;
    }
    .signup-form-container {
        border-radius: 0 !important;
        box-shadow: none !important;
        width: 100%;
    }
}



@media only screen and (max-width: 762px) {
    .header-signIn {
        display: none;
    }
    .signInMbtn {
        display: block;
        margin: 10px;
    }
    .btn-matches-normal {
        font-size: 12px;
        padding: 5px 15px;
    }
    .dash-filterbtn-active {
        font-size: 14px;
    }
    .personal-infos ul li {
        font-size: 10px !important;
    }
    .personal-infos ul {
        padding-left: 15px;
    }
    .profile-list-main ul {
        columns: 1 !important;
    }
}

@media only screen and (max-width: 1200px) {
    .logo {
        height: 50px;
    }
}

@media only screen and (max-width: 420px) {
    .logo {
        height: 35px;
    }
    .card-profile-img {
        width: 100px !important;
        height: 100px !important;
    }
}

@media only screen and (max-width: 767px) {
    .orderMd2 {
        order: 2;
    }
    .intro-details {
        padding: 30px 20px 30px 20px !important
    }
}

@media only screen and (max-width: 997px) {
    .hide-md {
        display: none;
    }
    .show-md-flex {
        display: flex;
    }
}

@media (min-width: 768px) {
    .req-pad:nth-child(0) {
        padding-left: 0.25rem !important;
    }
    .req-pad:nth-child(1) {
        padding-right: 0.25rem !important;
    }
}