/* sign in css */
/* desktop */
.header-signIn .login-form {
    display: flex;
    align-items: center;
    padding-left: 10px;
}


.header-signIn .forgot-link {
    font-size: 12px;
    color: #89867e;
}

.header-signIn .headerip {
    width: 205px;
}

/* mobile */
.signin-modal .headerip{
    margin-bottom: 1rem;
}

.signin-modal .login-btn{
    width: 100%;
}

.signin-modal .forgot-link {
    display: block;
    text-align: center;
    margin-top: 12px;
    font-size: 13px;
}