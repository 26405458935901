.chat-app {
    display: flex;
    height: calc(100% - 60px);
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    color: #515151;
}

.chat-app-header-height {
    height: 70px;
    flex: 0 0 70px;
}

.chat-app-left {
    max-width: 380px;
    width: 100%;
    height: 100%;
    border-right: 1px solid #cecece;
}

.chat-app-right {
    width: 100%;
    height: 100%;
    background: #e5ddd5;
}

.chat-app-left-header {
    background: #eeeeee;
    padding: 10px 15px;
}

.chatuser-list-container {
    height: calc(100% - 70px);
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
}

.btn-chat-filter {
    background: #fff;
    border-radius: 0;
    font-size: 14px;
}

.chatuser-list .chatuser {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #fff;
    box-shadow: none;
    display: block;
    outline: none;
    text-align: left;
    border: 0;
    color: #515151;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    width: 100%;
}

.chatuser-list .active {
    background: linear-gradient(to left, #ff6a00 0%, #ee0979 100%);
    color: #fff;
}

.chatuser-list .active .chat-user-msg {
    font-weight: 600;
}

.chatuser-image {
    display: block;
    float: left;
    height: 65px;
    width: 65px;
    border: 2px solid #f71455;
    padding: 2px;
    object-fit: cover;
    margin-right: 12px;
}

.chatuser-list .active .chatuser-image {
    border-color: #fff;
}

.chatuser-details {
    display: block;
    float: left;
    width: calc(100% - 79px);
    margin-top: 8px;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.chat-user-top-img {
    height: 50px;
    width: 50px;
    border: 1px solid #eeeeee;
    margin-right: 15px;
    object-fit: cover;
}

.chat-footer {
    background-color: #eeeeee;
    padding-top: 10px;
    padding-bottom: 10px;
}

.chatuser-msg-container {
    overflow-x: hidden;
    overflow-y: auto;
    background: #e5ddd5;
    padding: 25px 45px;
    flex: 1;
    display: flex;
    flex-flow: column-reverse nowrap;
}

.msg-ip {
    flex-grow: 1;
    border: none !important;
    height: 100%;
}

.msg-ip-con {
    height: 44px;
    border-radius: 25px;
    padding: 0 25px;
    background: #fff;
}

.chat-msg {
    border-radius: 20px;
    padding: 10px 20px;
    margin: 10px 0;
    min-width: 80px;
    /* max-width: 350px; */
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    height: fit-content;
    line-height: 1.2;
}

.chat-left {
    align-self: flex-start;
}

.chat-left .chat-msg {
    background: #f71455;
    color: #fff;
    font-weight: 500;
    border-top-left-radius: 0;
}

.chat-right {
    align-self: flex-end;
}

.chat-right .chat-msg {
    background: #fff;
    font-weight: 500;
    border-top-right-radius: 0;
}

.chat-msg-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0 10px;
}

.em-btn {
    background: transparent;
    padding: 0;
}

.em-row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.em-icon {
    width: 75px;
    height: 75px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.em-icon span {
    font-size: 1.4142em;
}

.em-active {
    height: 250px;
    margin-top: 15px;
}

.send-int-msg {
    padding: 5px 25px;
    font-size: 14px;
    border-radius: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    cursor: pointer;
    color: #515151;
    border-top-left-radius: 0px;
}

.send-int-msg-active {
    background: #eeeeee;
}

.send-invite-btn {
    padding: 5px 25px;
    border-radius: 27px;
    background: #f71858;
    color: #fff;
    font-size: 14px;
    float: right;
}

@media only screen and (max-width: 671px) {
    .chat-room-open .chat-app-right, .chat-room-close .chat-app-left {
        max-width: 100% !important;
    }

    .chat-room-close .chat-app-right, .chat-room-open .chat-app-left  {
        display: none !important;
    }
    
    .btn-chat-back {
        display: inline-block !important;
    }
}

.chatscroll::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.chatscroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #c1c1c1;
}

.shine {
    background-color: #f6f7f8 !important;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) !important; 
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    position: relative;
    height: 38px;
    width: 150px;
    box-shadow: none !important;
}

.btn-chat-back {
    display: none;
    color: #515151;
    font-size: 19px;
    width: 35px;
    height: 35px;
    padding: 0;
    background: transparent;
    margin-right: 5px;
}